// sito components
import { Box, Typography, useTheme } from "@mui/material";

// contexts
import { useLanguage } from "../../context/LanguageProvider";
import SitoImage from "sito-image";
import { useMode } from "../../context/ModeProvider";

// images
import logoLight from "../../assets/images/logo-light.png";
import logoDark from "../../assets/images/logo-dark.png";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import { Link } from "react-router-dom";
import { css } from "@emotion/css";

//hooks
import { useLocation } from "react-router-dom";

const Footer = () => {
    const { languageState } = useLanguage();
    const theme = useTheme();
    const { modeState, setModeState } = useMode();
    const { pathname } = useLocation();
    return (
        <Box
            sx={{
                display: pathname.includes("/comunidad") ? "none" : "flex",
                bottom: "0",
                left: "0",
                right: "0",
                background: theme.palette.background.paper,
                padding: { lg: "20px 2rem", xs: "20px" },
                flexDirection: "column",
                mt: "auto",
                gap: "40px",
            }}
        >
            <Box sx={{ display: { md: "flex", gap: { xl: "250", md: "4", xs: "3" } } }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <SitoImage
                        src={modeState.mode === "light" ? logoLight : logoDark}
                        alt="logo"
                        sx={{
                            width: "110px",
                            height: "25px",
                        }}
                    />
                    <Typography sx={{ fontSize: "15px", width: "300px" /*marginLeft: "20px" */ }}>
                        {languageState.texts.Footer.Text}
                    </Typography>
                    <Box sx={{ pb: { xs: "10px" } }}>
                        <Box sx={{ display: "flex" }}>
                            <Typography
                                variant="caption"
                                sx={{ marginRight: "5px", color: theme.palette.primary.main }}
                            >
                                {languageState.texts.Footer.Date}
                            </Typography>
                            <Typography variant="caption">
                                {languageState.texts.Footer.Company}
                            </Typography>
                        </Box>
                        <Typography variant="caption">
                            {languageState.texts.Footer.CopyRight}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: { xl: "160px", md: "90px", xs: "10px" },
                        justifyContent: { xs: "space-between", md: "flex-start" },
                        marginLeft: { md: "100px" },
                    }}
                >
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <Typography variant="caption" sx={{ color: theme.palette.primary.main }}>
                            {languageState.texts.Footer.Legal}
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            <Typography variant="caption">
                                {languageState.texts.Footer.TermsOfService}
                            </Typography>
                            <Typography variant="caption">
                                {languageState.texts.Footer.PrivacyPolicy}
                            </Typography>
                            <Typography variant="caption">
                                {languageState.texts.Footer.CookiesPolicy}
                            </Typography>
                        </Box>
                    </Box>
                    <Box mt="26px" display="flex" flexDirection="column">
                        <Typography variant="caption">
                            {languageState.texts.Footer.Address.Street}
                        </Typography>
                        <Typography variant="caption">
                            {languageState.texts.Footer.Address.City}
                        </Typography>
                        <Typography variant="caption">
                            {languageState.texts.Footer.Address.Country}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box
                            sx={{
                                display: "flex",
                                gap: 2,
                                color: theme.palette.primary.main,
                                marginBottom: "10px",
                            }}
                        >
                            <a
                                href="https://www.facebook.com/AltaVozApp"
                                className={css({
                                    textDecoration: "none",
                                    color: "inherit",
                                    cursor: "pointer",
                                })}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Box
                                    sx={{
                                        border: "1px solid gray",
                                        borderRadius: "10px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "35px",
                                        height: "35px",
                                    }}
                                >
                                    <FacebookIcon
                                        sx={{
                                            fontSize: "20px",
                                        }}
                                    />
                                </Box>
                            </a>
                            <a
                                href="https://instagram.com/alta_voz_oficial"
                                className={css({
                                    textDecoration: "none",
                                    color: "inherit",
                                    cursor: "pointer",
                                })}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Box
                                    sx={{
                                        border: "1px solid gray",
                                        borderRadius: "10px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "35px",
                                        height: "35px",
                                    }}
                                >
                                    <InstagramIcon sx={{ fontSize: "20px" }} />
                                </Box>
                            </a>
                            <a
                                href="https://x.com/AltaVozApp"
                                className={css({
                                    textDecoration: "none",
                                    color: "inherit",
                                    cursor: "pointer",
                                })}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Box
                                    sx={{
                                        border: "1px solid gray",
                                        borderRadius: "10px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "35px",
                                        height: "35px",
                                    }}
                                >
                                    <XIcon sx={{ fontSize: "20px" }} />
                                </Box>
                            </a>
                        </Box>
                        <Link
                            to=""
                            target="_blank"
                            className={css({
                                //textDecoration: "none",
                                color: "inherit",
                                cursor: "pointer",
                            })}
                        >
                            <Typography variant="caption">
                                {languageState.texts.Footer.OfficialSite}
                            </Typography>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Footer;
