import { matchPath } from "react-router-dom";
import { useLanguage } from "../context/LanguageProvider";

const useNavigationLinks = (roleName, path) => {
    const { languageState } = useLanguage();
    if (roleName === "Editor") return [path, languageState.texts.NavbarEditorial.Links];

    return getLinkData(path);
    function getLinkData(path) {
        const matchingLinkObject = languageState.texts.Navbar.Links.find((linkElement) =>
            matchPath(linkElement.pathExpression, path),
        );
        const { pathValue, links } = matchingLinkObject;
        const alphabeticallySortedLinks = links.slice().sort((a, b) => a.label.localeCompare(b.label));
        return [pathValue, alphabeticallySortedLinks];
    }
};

export default useNavigationLinks;
