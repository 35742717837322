import { 
    getEventsHome,
    getConcertsType,
    getReviews,
    getReviewId,
    getEventDetails,
} from "../actions";

export const concertLoader = ({ request }) => {
    const location = new URL(request.url);
    const page = location.searchParams.get("page") || 1;
    const response = getEventsHome({page});
    const resConcierto = getConcertsType({page: 1, type: "Concierto", pageSize: 8})
        .then((res) => res.json())
        .then((body) => body);
    const resFestivales = getConcertsType({page: 1, type: "Festivales", pageSize: 8})
        .then((res) => res.json())
        .then((body) => body);
    const resTeatros = getConcertsType({page: 1, type: "Teatros", pageSize: 8})
        .then((res) => res.json())
        .then((body) => body);
    const resSalas = getConcertsType({page: 1, type: "Salas", pageSize: 8})
        .then((res) => res.json())
        .then((body) => body);
    return {
        eventData: response,
        eventConcierto: resConcierto,
        eventFestivales: resFestivales,
        eventTeatros: resTeatros,
        eventSalas: resSalas,
    };
};
export const reviewLoader = () => {
    const response = getReviews()
    return { reviewData: response }
}
export const reviewDetailLoader = ({ request, params }) => {
    const { id } = params
    const response = getReviewId(id)
    return { reviewData: response }
}
export const eventDetailsLoader = ({ params }) => {
    const { id } = params
    const response = getEventDetails(id)
    return { 
        eventDetails: response,
        location: response.then(res => {
            return {
                id: res?.results?.[0]?.id,
                city: res?.results?.[0]?.city,
                place: res?.results?.[0]?.place
            }
        })
    }
}