//material components
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

//icons
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import KeyIcon from "@mui/icons-material/Key";
import LogoutIcon from "@mui/icons-material/Logout";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import DvrIcon from "@mui/icons-material/Dvr";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";

//redux
import { useSelector, useDispatch } from "react-redux";
import { signOut, changeThemeAction } from "../../redux/slices/userSlice";

//react
import { useState } from "react";

//swr
import { mutate } from "swr";

//react-router
import { useNavigate, useLocation } from "react-router-dom";

//actions
import { reloadHeaderLogout } from "../../actions";

//hooks
import { useLanguage } from "../../context/LanguageProvider";
import { useMode } from "../../context/ModeProvider";
import {
    DISABLE_CHANGE_THEME,
    ENABLE_CHANGE_THEME,
    THEME_DARK,
    ONE_CHANGE_THEME,
    THEME_LIGHT,
} from "../../constants/all";

const UserMenu = () => {
    const user = useSelector((state) => state.user);
    const theme = useSelector((state) => state.useThemePage);
    const [openDialog, setOpenDialog] = useState(false);
    const changeTheme = useSelector((state) => state.changeTheme);
    const navigate = useNavigate();
    const { modeState, setModeState } = useMode();
    const location = useLocation();
    const dispatchReductAction = useDispatch();
    const { languageState } = useLanguage();
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggleMode = (typeChange = ENABLE_CHANGE_THEME) => {
        if (changeTheme != DISABLE_CHANGE_THEME) {
            setModeState({ type: "toggle", theme: theme });
            dispatchReductAction(changeThemeAction({ type: typeChange }));
            handleClose();
        }
    };

    const handleTheme = () => {
        if (theme == THEME_DARK) {
            setOpenDialog(true);
        } else {
            handleToggleMode();
        }
    };

    const handleButtonsDialog = (select) => {
        setOpenDialog(false);
        handleToggleMode(select);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const gotoProfile = () => {
        handleClose();
        navigate("/profileform");
    };

    const gotoArtistDashboard = () => {
        handleClose();
        navigate("/dashboard-artist");
    };
    const gotoChat = () => {
        handleClose();
        navigate("/chat");
    };
    const startVideoCall = () => {
        handleClose();
        navigate("/audio_video_call");
    };
    const gotoLogin = () => {
        navigate("/auth");
    };

    const clearSwrCache = () => mutate((key) => Array.isArray(key), undefined, { revalidate: false });

    const logoutAction = () => {
        sessionStorage.clear();
        localStorage.removeItem("user_id");
        dispatchReductAction(signOut());
        clearSwrCache();
        reloadHeaderLogout();
        handleClose();
        if (location.pathname !== "/") navigate("/");
    };

    return (
        <>
            <Modal
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: "1.3rem 2rem 1rem 2rem",
                    }}
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {languageState.texts.Theme.Dialog.Title}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {languageState.texts.Theme.Dialog.Ask}
                    </Typography>

                    <Box
                        container
                        sx={{
                            display: "flex",
                            paddingTop: "1rem",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                        }}
                    >
                        <Button
                            sx={{
                                marginLeft: 0,
                                marginRight: "auto",
                            }}
                            onClick={() => handleButtonsDialog(ONE_CHANGE_THEME)}
                        >
                            {languageState.texts.Theme.Dialog.Buttons.OneUse}
                        </Button>

                        <Button
                            sx={{
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                            onClick={() => handleButtonsDialog(ENABLE_CHANGE_THEME)}
                        >
                            {languageState.texts.Theme.Dialog.Buttons.Alter}
                        </Button>

                        <Button
                            sx={{
                                marginLeft: "auto",
                                marginRight: 0,
                            }}
                            onClick={() => handleButtonsDialog(DISABLE_CHANGE_THEME)}
                        >
                            {languageState.texts.Theme.Dialog.Buttons.Always}
                        </Button>
                    </Box>
                </Box>
            </Modal>
            {user ? (
                <>
                    <IconButton
                        id="basic-button"
                        color="primary"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                    >
                        <AccountCircleIcon />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                    >
                        <MenuItem onClick={gotoProfile}>
                            <ListItemIcon>
                                <PersonIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText>{languageState.texts.Menu.profile}</ListItemText>
                        </MenuItem>

                        {user?.role_name === "Artista/Banda" || user?.role_name === "Profesional" ? (
                            <MenuItem onClick={gotoArtistDashboard}>
                                <ListItemIcon>
                                    <DvrIcon fontSize="small" color="primary" />
                                </ListItemIcon>
                                <ListItemText>{languageState.texts.Menu.artistDashboard}</ListItemText>
                            </MenuItem>
                        ) : (
                            ""
                        )}
                        {/*<MenuItem onClick={gotoChat}>
                            <ListItemIcon>
                                <QuestionAnswerIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText>{languageState.texts.Menu.goToChat}</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={startVideoCall}>
                            <ListItemIcon>
                                <VideoCallIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText>{languageState.texts.Menu.startVideoCall}</ListItemText>
                        </MenuItem>*/}

                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <KeyIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText>{languageState.texts.Menu.changePassword}</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={handleTheme} disabled={changeTheme == DISABLE_CHANGE_THEME}>
                            <ListItemIcon>
                                {theme == THEME_LIGHT ? (
                                    <DarkModeIcon fontSize="1.5rem" color="primary" />
                                ) : (
                                    <LightModeIcon fontSize="1.5rem" color="primary" />
                                )}
                            </ListItemIcon>
                            <ListItemText>{`${languageState.texts.Menu.changeMode[theme]}`}</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={logoutAction}>
                            <ListItemIcon>
                                <LogoutIcon fontSize="small" color="primary" />
                            </ListItemIcon>
                            <ListItemText>{languageState.texts.Menu.logout}</ListItemText>
                        </MenuItem>
                    </Menu>
                </>
            ) : (
                <>
                    <IconButton onClick={gotoLogin}>
                        <AccountCircleIcon color="primary" />
                    </IconButton>
                    <IconButton onClick={handleTheme} disabled={changeTheme == DISABLE_CHANGE_THEME}>
                        {theme == THEME_LIGHT ? (
                            <DarkModeIcon fontSize="1.5rem" color="primary" />
                        ) : (
                            <LightModeIcon fontSize="1.5rem" color="primary" />
                        )}
                    </IconButton>
                </>
            )}
        </>
    );
};

export default UserMenu;
